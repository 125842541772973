import { Link } from '@saas-ui/react'
import { NextSeoProps } from 'next-seo'
import { FaGithub, FaTwitter } from 'react-icons/fa'
import { FiCheck } from 'react-icons/fi'
import { Logo } from './logo'
import Services from './services'

const siteConfig = {
  logo: Logo,
  seo: {
    title: 'Crowsec Software',
    description: 'At Crowsec we build purpose driven software to let you focus on what matters to you',
  } as NextSeoProps,
  termsUrl: '#',
  privacyUrl: '#',
  header: {
    links: [
      {
        href: '/products',
        label: 'Products',
        menu: [
          {label: "Flock Inventory Solution", href: '/products/performance_inventory'},
          {label: "Crows Booking Solution", href: '/products/performance_inventory'},
          {label: "Charter CSV Pro", href: '/products/performance_inventory'},
        ]
      },
      {
        href: '/services',
        label: 'Services',
        menu: Services
      },
      {
        href: '/blog',
        // id: 'blog',
        label: 'Blog',
      },
      {
        href: '/faq',
        id: 'faq',
        label: 'FAQ',
      },
      {
        href: '/make-payment',
        // id: 'Pay-Invoice  ',
        label: 'Pay-Invoice ',
      },
      // {
      //   label: 'Login',
      //   href: '/login',
      // },
      // {
      //   label: 'Sign Up',
      //   href: '/signup',
      //   variant: 'primary',
      // },
    ],
  },
  footer: {
    copyright: (
      <>
        Website by{' '}
        <Link href="https://billsdev.space">Billy Best</Link>
      </>
    ),
    links: [
      {
        href: 'mailto:bill@tsuga.digital',
        label: 'Contact',
        text: 'me by email'
      },
      {
        href: 'https://twitter.com/crowsecsoftware',
        label: <FaTwitter size="14" />,
        text: 'twitter'
      },
      {
        href: 'https://github.com/ifroghop2worlds',
        label: <FaGithub size="14" />,
        text: 'github'
      },
    ],
  },
  signup: {
    title: 'Start building with CrowSec today!',
    features: [
      {
        icon: FiCheck,
        title: 'Problem Coverage',
        description:
          'Purpose built solutions designed to meet your exact needs when general solutions are lacking.',
      },
      {
        icon: FiCheck,
        title: 'Performance',
        description: 'We use modern technologies for bleeding edge speeds and efficiency. Not only creating a better user experience but effectivley lowering your hosting cost.',
      },
      {
        icon: FiCheck,
        title: 'Productivity',
        description:
          '100% problem coverage + Performance = Higher productivity + Happiness. A tailored solution means more time benefiting from it and less headaches.',
      },
      {
        icon: FiCheck,
        title: 'Security',
        description:
          'Following best practices when it comes to handling user data is our priority. We also do penetration testing on all our systems.',
      },
    ],
  },
}

export default siteConfig
