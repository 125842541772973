let additional = 3;
const Product_List = [
    {
        "name": "Flock Inventory Solution",
        "description": "Physical inventory management solution. Features barcode Scanner, powerful features to ensure correctness and efficiency, user systems, clean ui and more.",
        "features": [
            "Powerful concurrent sessions",
            "guards against partial scans",
            "tracks discrepancies and gives you the tools to manage and correct them",
            "intuitive to use",
            "fast and cost effective way to mobilize your workforce",
            "accurate results"
        ],
        "pricing": {
            "base_price": 69,
            "additional_stores": 15 * additional
        },
        "imageUrl": "/static/images/flockinvlogov1.jpg",
        "url": "/static/images/flockinvlogov1.jpg",
        "category": ["physical store", "commerce", "inventory", "business tools"]
    },

    {
        "name": "Crows Booking Solution",
        "description": "Use the api or our app to create services, set service providers and allow clients to book your services.",
        "features": [
            "Intuitive to use",
        ],
        "pricing": {
            "base_price": 420,
            "additional_stores": 15 * additional
        },
        "imageUrl": "/static/images/crowbookinglogov1.PNG",
        "url": "/products/performance_inventory",
        "category": ["scheduling", "business tools"]
    },

    {
        "name": "Charter CSV Pro",
        "description": " Cross-platform program allowing users to quickly navigate through their csv files and visualize data..",
        "features": [
            "Powerful concurrent sessions",
            "Fast operation on billions rows of data",
            "Powerful query and transformations without complex query language",
            "Intuitive to use",
            "Sessions",
            "File Editor"
        ],
        "pricing": {
            "base_price": 69,
            "additional_stores": 15 * additional
        },
        "imageUrl": "/static/images/sailboat.png",
        "url": "/products/performance_inventory",
        "category": ["data", "business tools"]
    },
]
 export default Product_List; 