import { chakra, HTMLChakraProps, useColorModeValue } from '@chakra-ui/react'
import Image from 'next/image'

export const Logo: React.FC<HTMLChakraProps<any>> = (props) => {
  return (
   <div className="translate-y-8">
     <Image loading="lazy" src="/static/images/crowlogo.PNG" alt="\PNG" width="120" height="120"  className=""></Image>
   </div>
  )
}
