import { VStack, forwardRef, Text, Box, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { Button, ButtonProps } from '@saas-ui/react'
import Link from 'next/link'
import Image from 'next/image'
import Product_List from 'data/products'
import { BackgroundGradient } from 'components/gradients/background-gradient'

export interface NavLinkProps extends ButtonProps {
  isActive?: boolean
  href?: string
  id?: string
  menu: {label: string, href: string}[] | undefined
}

export const NavLink = forwardRef<NavLinkProps, 'a'>((props, ref) => {
  const { href, type, isActive, ...rest } = props
  const [isOpen, setIsOpen] = React.useState(false);
  
  return (
    <div onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} className=''>
      <Button
      as={Link}
      href={href}
      ref={ref}
      variant="nav-link"
      lineHeight="2rem"
      isActive={isActive} 
      fontWeight="medium"
      {...rest}
    />
 
    {isOpen && props.menu != null && props.menu.length > 0 && (
        <Box className='grid place-items-center p-4 rounded-md absolute right-32 font-semibold w-2/5 ' bg='white' zIndex='100'>
          <BackgroundGradient height="100%" zIndex="-1" opacity="0.7"/>
          {props.menu?.map( (menuItem, idx) => {
            const product = Product_List.find(product => product.name === menuItem.label);
            if(product) {
              return (
                <div key={idx} className='bg-slate-100 border border-black p-3 mt-2 mb-2 h-auto w-2/3 overflow-y-scroll'>
                  <Link key={idx} href={menuItem.href} className='underline mb-2 text-sm  hover:text-purple-600'>
                    <article className="md:grid md:grid-cols-2 mx-auto">
                      <section className="col-start-1 col-span-1">
                        <h1 className="text-md text-black font-bold mb-4 pr-1">{product.name}</h1>
                        <Image src={product.imageUrl || '/default_image_path'} alt={''} width={150} height={150}/>            
                      </section>
                      <section className='col-start-2 col-span-1 mb-2 '>
                        <Box>
                          <VStack alignItems="flex-start" spacing="2">
                            <Text color="black" fontSize="sm" pt={12} >
                              {product.description} 
                            </Text>
                          </VStack>
                        </Box>
                      </section>
                      <section className='col-start-1 col-span-2'>
                      <p className='text-sm mt-1'>categories</p>
                        <ul className='flex mt-2'>
                          {product.category.map( (category, idx) => {
                            return (
                              <li key={idx} className='rounded bg-purple-600 text-cyan-200 w-fit list-none text-xs m-1'>{category}</li>
                            )
                          })}
                        </ul>
                      </section>
                    </article>
                  </Link>  
                </div>
              )
            } else {
              return (
                <Link key={idx} href={menuItem?.href} className='m-3 text-xl font-light w-96 hover:text-purple-600'>
                  {menuItem.label}
                </Link>
              )
            }
          })}
        </Box>
      )}
    </div>
  )
})

NavLink.displayName = 'NavLink'
